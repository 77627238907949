<i18n>
ru:
  title: Внимание!
  messageWithUnavailableProducts: 'Из корзины будут удалены следующие продукты:'
  messageWithAllProducts: Корзина будет очищена полностью.
  messageWithAllProductsContinue: Продолжить?
  onlyUnavailableProductsText: Только недоступные
  allProductsText: Все
  cancel: Отменить
  continue: Продолжить
  clearCartError: Ошибка при очистке корзины!
ua:
  title: Увага!
  messageWithUnavailableProducts: 'З кошика буде видалено такі продукти:'
  messageWithAllProducts: Кошик буде очищений повністю.
  messageWithAllProductsContinue: Продовжити?
  onlyUnavailableProductsText: Тільки недоступні
  allProductsText: Усе
  cancel: Скасувати
  continue: Продовжити
  clearCartError: Помилка під час очищення кошика!
us:
  title: Attention!
  messageWithUnavailableProducts: 'The following products will be removed from the
    cart:'
  messageWithAllProducts: The cart will be completely emptied.
  messageWithAllProductsContinue: Continue?
  onlyUnavailableProductsText: Only unavailable
  allProductsText: All
  cancel: Cancel
  continue: Continue
  clearCartError: Error emptying trash!
</i18n>

<template>
  <div class="v-popup-head v-title">
    <div
      class="v-popup-cleanup--title"
      v-html="translate('cleanupPopup.title')"
    />
  </div>
  <div class="v-popup-body">
    <div class="v-cart-cleanup--message v-d-flex v-flex-column v-mb-sm">
      <span
        v-if="isCartHaveSomeUnavailableProducts"
        v-html="translate('cleanupPopup.messageWithUnavailableProducts')"
      />
      <span
        v-if="!isCartHaveSomeUnavailableProducts"
        v-html="translate('cleanupPopup.messageWithAllProducts')"
      />
      <b
        v-if="!isCartHaveSomeUnavailableProducts"
        v-html="translate('cleanupPopup.messageWithAllProductsContinue')"
      />
    </div>
    <div class="v-cart-cleanup--options v-mb-sm">
      <arora-radio-input
        v-if="isCartHaveSomeUnavailableProducts"
        as-column
        hide-label
        :label="translate('cleanupPopup.messageWithUnavailableProducts')"
        :options="[true, false]"
        v-model:selected="needToClearOnlyUnavailableProducts"
      >
        <template #option="option">
          <span
            :data-test-id="
              option.value
                ? 'cart-cleanup-unavailable-products-radio'
                : 'cart-cleanup-all-products-radio'
            "
            v-html="
              translate(
                option.value
                  ? 'cleanupPopup.onlyUnavailableProductsText'
                  : 'cleanupPopup.allProductsText'
              )
            "
          />
        </template>
      </arora-radio-input>
    </div>
    <div class="v-d-flex v-flex-column">
      <arora-button
        class-name="v-mb-sm"
        :label="translate('cleanupPopup.continue')"
        data-test-id="cart-cleanup-popup-continue-button"
        @click="handleContinueClick"
      />
      <arora-button
        class-name="v-btn"
        ignore-settings
        :label="translate('cleanupPopup.cancel')"
        data-test-id="cart-cleanup-popup-cancel-button"
        @click="handleCancelClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { translate } = useI18nSanitized()

const isCartHaveSomeUnavailableProducts = ref<boolean>(true)
const needToClearOnlyUnavailableProducts = ref<boolean>(true)

const handleCancelClick = async (): Promise<void> => {
  await popupStore.closePopup()
}

const handleContinueClick = async (): Promise<void> => {
  const didProductsRemoved = await clientStore.removeProductsFromCart(
    needToClearOnlyUnavailableProducts.value
  )

  await popupStore.closePopup()

  if (!didProductsRemoved) {
    await popupStore.showError(translate('cleanupPopup.clearCartError'))
  }
}

onMounted(() => {
  isCartHaveSomeUnavailableProducts.value =
    clientStore.ClientState?.data?.Cart?.Content?.some((item) => !item.Active || item.Forbidden) ?? false
  if (!isCartHaveSomeUnavailableProducts.value) needToClearOnlyUnavailableProducts.value = false
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-popup-head {
  .v-popup-cleanup--title {
    width: 100%;
    font-weight: 600;
    text-align: center;
    font-size: variables.$TextSizeH3;
  }
}

.v-popup-content {
  .v-popup-body {
    .v-cart-cleanup {
      &--message {
        font-weight: 500;
      }

      &--options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
</style>
